<template>
	<div class="type-select">
		<Header></Header>
		<div class="wrapper">
			<el-breadcrumb separator="/" style="margin-top: 20px;">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/carId' }">{{car.number}}</el-breadcrumb-item>
				<el-breadcrumb-item >养护列表</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="form-wrapper shadow">
				<div class="main-title">提醒</div>
				<ul class="remind-list">
					<li @click="remindClick(0)" v-if="car.nextMileage - car.mileage <= 3000">
						<span class="title"><i></i>保养提醒</span>
						<div class="right">
							<span>保养还剩：{{car.nextMileage - car.mileage}}公里</span>
							<i class="el-icon-arrow-right"></i>
						</div>
					</li>
					<li @click="remindClick(1)" v-if="car.insuranceDate">
						<span class="title"><i></i>保险到期提醒</span>
						<div class="right">
							<span>到期时间：{{car.insuranceDate}}</span>
							<i class="el-icon-arrow-right"></i>
						</div>
					</li>
					<li @click="remindClick(2)" v-if="car.insepctionDate">
						<span class="title"><i></i>车审提醒</span>
						<div class="right">
							<span>到期时间：{{car.insepctionDate}}</span>
							<i class="el-icon-arrow-right"></i>
						</div>
					</li>
				</ul>
			</div>
			<div class="main-title">
				养护列表
				<div class="right">
					<!-- <el-button v-if="stepAddDevice && car.mplanNew == 1 && car.mplanStep == 1" type="primary"
						@click="handleAddDevice(car)">加装设备</el-button>
					<el-button v-if="stepSent && car.mplanNew == 1 && car.mplanStep == 2" type="primary"
						@click="handleSent(car)">用户提车</el-button>
					<el-button v-if="stepAddDevice && car.mplanNew == 0 && car.mplanStep == 2" type="primary"
						@click="handleAddDevice(car)">加装设备</el-button>
					<el-button v-if="stepSent && car.mplanNew == 0 && car.mplanStep == 3" type="primary"
						@click="handleSent(car)">用户提车</el-button> -->
					<el-button v-if="stepAddDevice" @click="addCheck(car)" type="primary">新增养护</el-button>
				</div>
			</div>
			<checkList :checkList="checkList" :number="car.number"></checkList>
		</div>
		<Footer></Footer>

		<el-dialog title="加装设备" :visible.sync="dialogAddDeviceShow">
			<el-form ref="addDeviceForm" :rules="addDeviceRules" :model="carPost" label-position="left"
				label-width="150px" style="width: 400px; margin-left:50px;">
				<el-form-item label="设备" prop="addDevice">
					<el-radio label="1" v-model="carPost.addDevice">确认已加装</el-radio>
				</el-form-item>
				<el-form-item label="下次保养里程数" prop="nextMileage">
					<el-input v-model="carPost.nextMileage" />
				</el-form-item>
				<el-form-item label="保养到期日期" prop="maintainDate">
					<el-date-picker v-model="carPost.maintainDate" type="date" value-format="yyyy-MM-dd"
						placeholder="选择日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="备注" prop="mplanAddDeviceRemark">
					<el-input v-model="carPost.mplanAddDeviceRemark" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogAddDeviceShow = false">
					取消
				</el-button>
				<el-button type="primary" @click="submitAddDevice">
					提交
				</el-button>
			</div>
		</el-dialog>

		<el-dialog title="用户提车" :visible.sync="dialogSentShow">
			<el-form ref="sentForm" :model="carPost" :rules="sentRules" label-position="left" label-width="150px" style="width: 400px; margin-left:50px;">
				<el-form-item label="提车" prop="sent">
					<el-radio label="1" v-model="carPost.sent">确认已提车</el-radio>
				</el-form-item>
				<el-form-item label="提车细节备注" prop="mplanSentRemark">
					<el-input v-model="carPost.mplanSentRemark" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogSentShow = false">
					取消
				</el-button>
				<el-button type="primary" @click="submitSent">
					提交
				</el-button>
			</div>
		</el-dialog>

		<el-dialog title="新增养护" :visible.sync="dialogCheckShow">
			<el-form ref="checkForm" :model="check" :rules="checkRules" label-position="left" label-width="150px" style="width: 400px; margin-left:50px;">
				<el-form-item label="维修工" prop="userName">
					<el-input v-model="check.userName" placeholder="请输入维修工姓名" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogCheckShow = false">
					取消
				</el-button>
				<el-button type="primary" @click="submitCheck">
					提交
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import http from '../../http.js'
	import Header from "../../components/header.vue"
	import Footer from "../../components/footer.vue"
	import checkList from "../../components/checkList.vue"
	export default {
		name: 'Home',
		components: {
			Header,
			Footer,
			checkList
		},
		data() {
			return {
				stepInsurance: false,
				stepAddDevice: false,
				stepSent: false,
				car: {
					id: '',
					number: '',
				},
				checkList:[],
				dialogAddDeviceShow: false,
				dialogSentShow: false,
				addDeviceRules: {
					addDevice: [{
						required: true,
						message: '请确认是否已加装设备',
						trigger: 'blur'
					}],
					nextMileage: [{
						required: true,
						message: '请输入下次保养里程',
						trigger: 'blur'
					}],
					maintainDate: [{
						required: true,
						message: '请输入下次保养日期',
						trigger: 'change'
					}],
				},
				sentRules: {
					sent: [{
						required: true,
						message: '请确认用户已提车',
						trigger: 'blur'
					}]
				},
				checkRules: {
					userName: [{
						required: true,
						message: '请输入维修工姓名',
						trigger: 'blur'
					}]
				},
				dialogCheckShow: false,
				check: {
					id: 0,
					carId: 0,
					checkTime: '',
					details: [],
					files0: [],
					files1: [],
					upfiles0: [],
					upfiles1: [],
					status: 0,
					userName: ''
				},
				carPost: {
					sent: '',
					addDevice: '',
					nextMileage: '',
					maintainDate: '',
					mplanAddDeviceRemark: '',
					mplanSentRemark: ''
				},
			}
		},
		created() {
			for (let i = 1; i <= 8; i++) {
				this.check.details.push({
					itemId: i,
					files: [],
					upfiles: []
				})
			}

			let roles = localStorage.getItem('roles').split(',')
			if(roles.indexOf('mplan-rent') > -1){
				this.stepInsurance = true
			}
			if(roles.indexOf('mplan-service') > -1){
				this.stepAddDevice = true
			}
			if(roles.indexOf('mplan-sell') > -1){
				this.stepSent = true
			}

			if (this.$route.query.carId) {
				this.car.id = this.$route.query.carId
			}
			if (this.$route.query.carNumber) {
				this.car.number = this.$route.query.carNumber
			}
			this.getCar()
			this.getList()
		},
		methods: {
			getCar(){
				http.fetchGet('/api/admin/car', { id: this.car.id }).then((res) => {
					if (res.data.code == 0) {
						this.car = res.data.data
					} else {
						this.$message.error(res.data.desc)
					}
				})
			},
			getList(){
				http.fetchGet('/api/v2/admin/car_check/list', { car_id: this.car.id, page: 1, size: 9999 }).then((res) => {
					if (res.data.code == 0) {
						this.checkList = res.data.data.list
					} else {
						this.$message.error(res.data.desc)
					}
				})
			},
			remindClick(type){
				this.$router.push({
					path: "/remindDetail",
					query: {
						id: this.car.id,
						type: type
					}
				})
			},
			handleAddDevice(row) {
				this.carPost.id = row.id
				this.carPost.addDevice = ''
				this.carPost.nextMileage = row.next_mileage
				this.carPost.mplanAddDeviceRemark = row.mplan_add_device_remark
				this.carPost.maintainDate = row.maintain_date
				this.dialogAddDeviceShow = true
			},
			handleSent(row) {
				this.carPost.id = row.id
				this.carPost.mplanSentRemark = row.mplan_sent_remark
				this.dialogSentShow = true
			},
			submitAddDevice() {
				this.$refs['addDeviceForm'].validate((valid) => {
					if (valid) {
						http.fetchPost('/api/admin/mplan/add-device', this.carPost).then((res) => {
							if (res.data.code == 0) {
								this.getCar()
								this.dialogAddDeviceShow = false
								this.$message.success('操作成功')
							} else {
								this.$message.error(res.data.desc)
							}
						})
					}
				})
			},
			submitSent() {
				this.$refs['sentForm'].validate((valid) => {
					if (valid) {
						http.fetchPost('/api/admin/mplan/sent', this.carPost).then((res) => {
							if (res.data.code == 0) {
								this.getCar()
								this.dialogSentShow = false
								this.$message.success('操作成功')
							} else {
								this.$message.error(res.data.desc)
							}
						})
					}
				})
			},
			addCheck(item){
				this.check.carId = item.id
				this.check.userName = ''
				this.dialogCheckShow = true
			},
			submitCheck() {
				this.$refs['checkForm'].validate((valid) => {
					if (valid) {
						http.fetchPost('/api/admin/mplan/car_check', this.check).then((res) => {
							if (res.data.code == 0) {
								this.getList()
								this.dialogCheckShow = false
								this.$message.success('添加成功')
							} else {
								this.$message.error(res.data.desc)
							}
						})
					}
				})
			},
		}
	}
</script>
<style lang="scss" scoped>

</style>
