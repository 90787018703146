<template>
	<div class="check-list">
		<!-- <ul>
			<li class="shadow" v-for="value in checkList" :key="value.id" @click="checkForm(value)">
				<div class="top">
					<div class="fleet-name"><i class="el-icon-truck"></i><span>{{value.fleet}}</span></div>
					<div class="number">{{value.number}}</div>
				</div>
				<div class="bottom">
					<span class="time">{{value.checkTime}}</span>
					<div class="right">
						<el-tag v-if="value.type_in == 1 && value.passed == 9" type="success" size="small">
							合格({{value.passed}}/9)</el-tag>
						<el-tag v-if="value.type_in == 1 && value.passed < 9" type="danger" size="small">
							合格({{value.passed}}/9)</el-tag>
						<el-tag v-if="value.status == 1" type="success" size="small">已检测</el-tag>
						<el-tag v-if="value.status == 0" type="danger" size="small">待检测</el-tag>
						<el-tag v-if="value.type_in == 1" type="success" size="small">已录入</el-tag>
						<el-tag v-if="!value.type_in || value.type_in == 0" type="danger" size="small">未录入</el-tag>
						<i v-if="value.status == 1" class="el-icon-arrow-right"></i>
					</div>
				</div>
			</li>
		</ul> -->
		<el-table :data="checkList" stripe style="width: 100%" :row-class-name="tableRowClassName">
			<el-table-column label="车牌号">
				<template prop="number" slot-scope="scope">
					<span>{{scope.row.number}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="fleet" label="所属车队" width="200"></el-table-column>
			<el-table-column prop="checkTime" label="养护时间"></el-table-column>
			<el-table-column label="车辆状态" width="300">
				<template slot-scope="scope">
					<el-tag v-if="scope.row.mplanCPIC == 1 && scope.row.type_in == 1 && scope.row.passed == 9" type="success" size="small">
						合格({{scope.row.passed}}/9)</el-tag>
					<el-tag v-if="scope.row.mplanCPIC == 1 && scope.row.type_in == 1 && scope.row.passed < 9" type="danger" size="small">
						合格({{scope.row.passed}}/9)</el-tag>
					<el-tag v-if="scope.row.status == 1" type="success" size="small">已养护</el-tag>
					<el-tag v-if="scope.row.status == 0" type="danger" size="small">待养护</el-tag>
					<el-tag v-if="scope.row.type_in == 1" type="success" size="small">已录入</el-tag>
					<el-tag v-if="!scope.row.type_in || scope.row.type_in == 0" type="danger" size="small">未录入</el-tag>
				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button v-if="scope.row.status == 1 && scope.row.type_in != 1" type="primary" size="mini" @click="checkForm(scope.row)">录入</el-button>
					<el-button v-if="scope.row.status == 1 && scope.row.type_in == 1" type="primary" size="mini" @click="checkForm(scope.row)">编辑</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	export default {
		props: ['checkList', 'number'],
		methods: {
			checkForm(item) {
				if (item.status == 1) {
					this.$router.push("/generalForm?id=" + item.id + "&number=" + this.number)
				}
			}
		}
	}
</script>

<style lang="scss">
	.el-tag{
		margin-right: 6px;
	}
</style>
